/* You can add global styles to this file, and also import other style files */
:root {
  --blue-primary: #01737d;
  --blue-secondary: #18cdde;
  --green-primary: #bdcf26;
  --green-secondary: #008e58;
}

// // Estilização Primeng
@import "primeicons/primeicons.css";

@font-face {
  font-family: "Play";
  src: url("src\assets\fonts\Play-Regular.ttf");
}

@font-face {
  font-family: "PlayFair";
  src: url("src\assets\fonts\PlayfairDisplay-Regular.ttf");
}

a,
p {
  font-family: "PlayFair";
}

span,
h1 {
  font-family: "Play", Arial, Helvetica, sans-serif;
}

.json-key {
  color: #6cb8e6
}

.json-value {
  color: #e6d37a
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.hint-text {
  font-size: 0.8rem;
  color: var(--blue-primary);
}

.btn {
  border-radius: 20px;
  color: white;
}

.btn-success {
  background-color: var(--green-secondary);
  border-color: var(--green-secondary);
}

//Estilização da table
table,
.table-div {
  width: 100%;
  overflow: auto;
  border-radius: 10px;
}

tr.mat-row:nth-child(even) {
  background-color: rgba(25, 135, 84, 0.5);
}

th.mat-header-cell {
  font-weight: bold;
  font-size: 0.9rem;
  color: var(--blue-primary);
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 2;
}

//Componentização da estrutura dos componentes
.component-main-container {
  height: 100vh;
  padding: 1rem 1rem 1rem calc(78px + 1rem);
  background-color: rgb(218, 218, 218);
  overflow-y: auto;
  min-height: 800px;

  .component-forms-box {
    width: 100%;
    height: 100%;

    .closed {
      max-height: 80px !important;
      transition: max-height 0.5s cubic-bezier(0.5, 0.5, 0.5, 0.5) !important;
    }

    .form-fields-box {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      height: auto;
      min-width: 250px;
      padding: 1rem;
      margin: 1rem 0;
      background-color: white;
      border-radius: 15px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      transition: height 0.5s ease-in-out;

      .mat-slide-toggle {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
      }

      h2 {
        cursor: pointer;
        transition: 0.5s ease-in-out;
      }

      h2:hover {
        color: var(--green-primary);
      }

      .form-step {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .circle-number {
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-weight: bold;
          border: 3px solid var(--blue-primary);
          margin: 0 0 1rem 0;
          color: var(--blue-primary);
          padding: 10px;
          transition: 0.5s ease-in-out;
        }

        //Quando o formulário foi preenchido corretamente.
        .circle-number-ready {
          color: var(--green-primary);
          border: 3px solid var(--green-primary);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.5rem;
          font-weight: bold;
          margin: 0 0 1rem 0;
          padding: 10px;
          transition: 0.5s ease-in-out;
        }
      }
    }

    .mat-mdc-radio-group {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      margin-bottom: 35px;
    }

    .form-fields-box-open {
      height: auto;
    }

    hr {
      width: 130px;
      background-color: var(--green-primary);
      opacity: 100%;
      margin: -0.5rem auto 2rem auto;
      border: none;
      min-height: 2.5px;
      transition: 0.6s ease-in-out;
    }

    .hr-open {
      width: 70%;
    }

    mat-form-field {
      width: 100%;
      margin: 0 0.5rem;

      ::ng-deep .mat-form-field-infix {
        display: flex !important;
      }
    }
  }

  .component-table-box {
    width: 100%;
    height: auto;
    justify-content: center;
    max-height: 90vh;
    overflow: auto;
    background-color: white;
    border-radius: 15px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    margin: 1rem 1rem 0 0;

    table {
      width: 100%;
    }
  }

  .component-table-action-buttons {
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: flex-end;
  }
}

.component-spinner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
}

.component-spinner-box-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  margin: 1rem;
}

.component-search-icon {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  color: black;
  margin-bottom: 0 auto;
}

.component-search-icon-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.component-search-icon:hover {
  color: var(--green-primary);
}

.component-table-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  padding: 1rem;
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  margin: 1rem 1rem 0 0;
}

mat-icon {
  cursor: pointer;
}

app-select-checkbox {
  width: 100%;
  padding-right: 1rem;
}

.gateway-select-panel {
  max-height: 320px !important;
}

// .sidebar-edit {
//   position: absolute;
//   right: 0;
//   top: 0;
//   overflow: hidden;
//   z-index: 2;
//   background-color: rgb(255, 255, 255);
//   height: 100%;
//   width: 500px;
//   padding: 10px;
//   border-radius: 20px 0 0 20px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-around;
//   box-shadow: -10px 0px 11px 0px rgba(0, 0, 0, 0.1);
//   -webkit-animation-name: slideInRight;
//   animation-name: slideInRight;
//   -webkit-animation-duration: 1s;
//   animation-duration: 1s;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;

//   button {
//     width: 80%;
//   }

//   h2 {
//     cursor: pointer;
//     transition: 0.5s ease-in-out;
//     margin: 0;
//   }

//   h2:hover {
//     color: var(--green-primary);
//   }

//   mat-icon {
//     display: flex;
//     align-self: flex-start;
//   }
// }

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}


/* Estilizando a barra de rolagem para navegadores baseados no WebKit (Google Chrome) */
/* Estilizando a barra de rolagem quando o mouse não estiver sobre a área de rolagem */

::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
}

::-webkit-scrollbar-track {
  background: #ffffff;
  /* color of the tracking area */
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 10px;
  /* Largura da barra de rolagem vertical */
  background-color: #adb5bd;
  /* Cor de fundo da barra de rolagem */
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

// Label dos gateways no mapa
.labels {
  color: black;
  background-color: white;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding: 2px;
  border: 1px solid #999;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 5px;
  margin-top: 74px;
  transform: translateX(-3%);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/** Estilizações do sidebar de edição **/
.sidebar-edit {
  justify-content: start;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 2;
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 20%;
  padding: 10px;
  border-radius: 20px 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -10px 0px 11px 0px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  min-height: 800px;

  h2 {
    margin-top: 106px;
    margin-bottom: 12px;
    font: bold;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    text-align: center;
  }

  h2:hover {
    color: var(--green-primary);
  }

  button {
    width: 100%
  }

  mat-form-field {
    width: 100%;
  }

  mat-icon {
    display: flex;
    align-self: flex-start;
    margin-top: 7px;
  }

  .component-search-icon {
    margin: -20px 0px 0px 0px;
  }

  .component-close-icon {
    position: fixed;
  }
}


.component-svg-icon {
  padding-top: 3px;
  width: 17px !important;
}